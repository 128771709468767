@use '@angular/material' as mat;
// Import all the tools needed to customize the theme and extract parts of it
@import "@angular/material/theming";

// Define a mixin that accepts a theme and outputs the color styles for the component.
@mixin message-set-viewer-color($config-or-theme) {
  // Extract the color configuration in case a theme has been passed.
  // This allows consumers to either pass a theme object or a color configuration.
  $config: mat-get-color-config($config-or-theme);
  // Extract whichever individual palettes you need from the theme.
  $primary: map-get($config, primary);
  $accent: map-get($config, accent);
  $warn: map-get($config, warn);

  .message-set-viewer {
    h2 {
      color: mat.get-color-from-palette($accent, "100-contrast");
    }
  }
}

@mixin message-set-viewer-typography($config-or-theme) {
  // Extract the typography configuration in case a theme has been passed.
  $config: mat-get-typography-config($config-or-theme);

  .message-set-viewer {
    font: {
      family: mat.font-family($config, body-1);
      size: mat.font-size($config, body-1);
      weight: mat.font-weight($config, body-1);
    }
  }
}

@mixin message-set-viewer-theme($theme) {
  // Extracts the color and typography configurations from the theme.
  $color: mat-get-color-config($theme);
  $typography: mat-get-typography-config($theme);

  // Do not generate styles if configurations for individual theming
  // systems have been explicitly set to `null`.
  @if $color != null {
    @include message-set-viewer-color($color);
  }
  @if $typography != null {
    @include message-set-viewer-typography($typography);
  }
}
