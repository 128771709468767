@use '@angular/material' as mat;
@use './app/shared/components/message-set-history-viewer/message-set-history-viewer-theme' as message-set-history-viewer;
// Plus imports for other components in your app.

// Include the base styles for Angular Material core. We include this here so that you only
// have to load a single css file for Angular Material in your app.
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
/* For use in src/lib/core/theming/_palette.scss */
$md-cmprimary: (
  50 : #f9e2e8,
  100 : #f0b8c6,
  200 : #e688a0,
  300 : #db5879,
  400 : #d4355d,
  500 : #cc1140,
  600 : #c70f3a,
  700 : #c00c32,
  800 : #b90a2a,
  900 : #ad051c,
  A100 : #ffd8dc,
  A200 : #ffa5ad,
  A400 : #ff727f,
  A700 : #ff5968,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$md-cmaccent: (
  50 : #e2f9f3,
  100 : #b8f0e2,
  200 : #88e6ce,
  300 : #58dbba,
  400 : #35d4ac,
  500 : #11cc9d,
  600 : #0fc795,
  700 : #0cc08b,
  800 : #0ab981,
  900 : #05ad6f,
  A100 : #d8ffef,
  A200 : #a5ffda,
  A400 : #72ffc5,
  A700 : #59ffba,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$md-cmtertiary: (
  50 : #e1efed,
  100 : #b3d6d1,
  200 : #80bbb3,
  300 : #4da095,
  400 : #278b7e,
  500 : #017767,
  600 : #016f5f,
  700 : #016454,
  800 : #015a4a,
  900 : #004739,
  A100 : #7bffdd,
  A200 : #48ffd0,
  A400 : #15ffc3,
  A700 : #00fbbb,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);




$cm-primary: mat.define-palette($md-cmprimary, 500, A100, 800);
$cm-accent: mat.define-palette($md-cmaccent, 500, A100, 800);
// The warn palette is optional (defaults to red).
$cm-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$cm-theme: mat.define-light-theme($cm-primary, $cm-accent, $cm-warn);

// Import custom component themes
@import "app/shared/components/overlay/overlay-component-theme.scss";
@import "app/shared/components/setup/setup-component-theme.scss";
@import "app/shared/components/accept-call-dialog/accept-call-dialog-component-theme.scss";
@import "app/shared/components/choice-selector/choice-selector-choice/choice-selector-choice-component-theme.scss";
@import "app/church/church-choice-selector/church-choice-selector-choice/church-choice-selector-choice-component-theme.scss";
@import "app/shared/components/message-set-viewer/message-set-viewer-theme.scss";
@import "app/shared/components/message-count/message-count-component-theme.scss";
@import "app/shared/components/menu-grid/menu-grid-cell/menu-grid-cell-component-theme.scss";

// Custom component themes
@include overlay-component-theme($cm-theme);
@include setup-component-theme($cm-theme);
@include accept-call-dialog-component-theme($cm-theme);
@include choice-selector-choice-component-theme($cm-theme);
@include church-choice-selector-choice-component-theme($cm-theme);
@include message-set-viewer-theme($cm-theme);
@include message-count-component-theme($cm-theme);
@include menu-grid-cell-component-theme($cm-theme);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($cm-theme);
@include message-set-history-viewer.theme($cm-theme);

.cm-dark-theme {
  $dark-primary: mat.define-palette(mat.$indigo-palette, 700, 500, 900);
  $dark-accent: mat.define-palette(mat.$pink-palette);
  $dark-warn: mat.define-palette(mat.$red-palette);

  $dark-theme: mat.define-dark-theme($dark-primary, $dark-accent, $dark-warn);

  // Custom component themes
  @include overlay-component-theme($dark-theme);
  @include setup-component-theme($dark-theme);
  @include accept-call-dialog-component-theme($dark-theme);
  @include choice-selector-choice-component-theme($dark-theme);
  @include church-choice-selector-choice-component-theme($dark-theme);
  @include message-set-viewer-theme($dark-theme);
  @include message-count-component-theme($dark-theme);
  @include menu-grid-cell-component-theme($dark-theme);
  @include mat.all-component-themes($dark-theme);
}

.cm-high-contrast-theme {
  $hc-primary: mat.define-palette(mat.$blue-palette);
  $hc-accent: mat.define-palette(mat.$yellow-palette, A200, A100, A400);
  $hc-warn: mat.define-palette(mat.$red-palette);

  $hc-theme: mat.define-dark-theme($hc-primary, $hc-accent, $hc-warn);

  // Custom component themes
  @include overlay-component-theme($hc-theme);
  @include setup-component-theme($hc-theme);
  @include accept-call-dialog-component-theme($hc-theme);
  @include choice-selector-choice-component-theme($hc-theme);
  @include church-choice-selector-choice-component-theme($hc-theme);
  @include message-set-viewer-theme($hc-theme);
  @include message-count-component-theme($hc-theme);
  @include menu-grid-cell-component-theme($hc-theme);

  @include mat.all-component-themes($hc-theme);
}
