@use '@angular/material' as mat;
// Import all the tools needed to customize the theme and extract parts of it

// Define a mixin that accepts a theme and outputs the color styles for the component.
@mixin message-count-component-theme($theme) {
  // Extract whichever individual palettes you need from the theme.
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);

  .message-count {
    width: 180px;
    float: right;
    background-color: mat.get-color-from-palette($accent);
    padding: 6px;
    border-radius: 6px;
    margin-right: 100px;
    font-size: 1.8rem;
  }
}
