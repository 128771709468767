@use '@angular/material' as mat;
// Import all the tools needed to customize the theme and extract parts of it
// Import library functions for theme creation.
@import "@angular/material/theming";

@mixin overlay-component-color($config-or-theme) {
  // Extract the color configuration in case a theme has been passed.
  // This allows consumers to either pass a theme object or a color configuration.
  $config: mat-get-color-config($config-or-theme);
  // Extract the palettes you need from the theme definition.
  $primary: map-get($config, primary);
  $accent: map-get($config, accent);
  $warn: map-get($config, warn);
  $background: map-get($config, background);
  $foreground: map-get($config, foreground);

  // Use mat-color to extract individual colors from a palette as necessary.
  .overlay {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #666;
    border-radius: 5px;
    box-shadow: 0 0 40px 10px rgba(0, 0, 0, 0.4);
    width: 50%;
    height: 30%;
    background-color: mat.get-color-from-palette($background, background);

    .overlay-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding-left: 20px;
      border-radius: 5px 5px 0 0;
      box-sizing: border-box;
      background-color: mat.get-color-from-palette($primary);
      color: white;
      width: 100%;
      height: 60px;
      font-size: 2.5rem;

      &.center {
        justify-content: center;
        padding: 0;
      }
    }

    .overlay-header-avatar {
      position: absolute;
      top: -50px;
      right: -50px;
      z-index: 999;
      width: 160px;
      height: 160px;
      border-radius: 80px;
      background-color: mat.get-color-from-palette($primary);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .overlay-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      align-items: center;
      justify-content: center;
      width: 100%;
      background-color: mat.get-color-from-palette($background, background);

      h1,
      h2 {
        color: mat.get-color-from-palette($foreground);
      }
    }

    .overlay-content.justify-top {
      justify-content: flex-start;
    }
  }
}

@mixin overlay-component-typography($config-or-theme) {
  // Extract the typography configuration in case a theme has been passed.
  $config: mat-get-typography-config($config-or-theme);

  .overlay {
    font: {
      family: mat.font-family($config, body-1);
      size: mat.font-size($config, body-1);
      weight: mat.font-weight($config, body-1);
    }
  }
}

@mixin overlay-component-theme($theme) {
  // Extracts the color and typography configurations from the theme.
  $color: mat-get-color-config($theme);
  $typography: mat-get-typography-config($theme);

  // Do not generate styles if configurations for individual theming
  // systems have been explicitly set to `null`.
  @if $color != null {
    @include overlay-component-color($color);
  }
  @if $typography != null {
    @include overlay-component-typography($typography);
  }
}
