@use '@angular/material' as mat;
// Import all the tools needed to customize the theme and extract parts of it

// Define a mixin that accepts a theme and outputs the color styles for the component.
@mixin menu-grid-cell-component-theme($theme) {
  // Extract whichever individual palettes you need from the theme.
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  //$background: map-get($theme, background);

  // Use mat-color to extract individual colors from a palette as necessary.
  div.cm-menu-grid-cell {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #666;
    //background-color: mat.get-color-from-palette($primary);
    //border: 5px solid #ccc;
    border-radius: 5px;
    text-align: center;
    vertical-align: middle;
    z-index: 1;

    &.active {
      background-color: mat.get-color-from-palette($accent);
      z-index: 10;
      color: #fff;
    }

    &:focus {
      outline: none;
    }

    .current-choice {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 16px;
      right: 20px;
      background-color: #fff;
      border-radius: 20px;
    }

    .label {
      margin-top: 10px;
      font-size: 20px;
    }
  }
}

// td.cm-menu-grid-cell {
//   position: relative;
//   box-sizing: border-box;
//   padding: 0;
//   color: #fff;
//   border: 10px solid #ccc;
//   border-radius: 5px;
//   text-align: center;
//   vertical-align: middle;
//   z-index: 1;

//   &.active {
//     border: 10px solid lawngreen;
//     z-index: 10;
//   }

//   &:focus {
//     outline: none;
//   }

//   .image {
//     position: relative;
//     box-sizing: border-box;
//     width: 200px;
//     height: 200px;
//     background-size: cover;
//   }
// }
