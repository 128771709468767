@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);

  // Get the primary color palette from the color-config.
  $primary-palette: map.get($color-config, 'primary');

  // Get the accent color palette from the color-config.
  $accent-palette: map.get($color-config, 'accent');

  // Get the warn color palette from the color-config.
  $warn-palette: map.get($color-config, 'warn');

  #history-container {
    #header {
      #message-count {
        background-color: mat.get-color-from-palette($accent-palette, 900);
      }
    }
  }

  .response-label {
    background-color: mat.get-color-from-palette($accent-palette, 500);
  }
}

@mixin typography($theme) {
  // Get the typography config from the theme.
  $typography-config: mat.get-typography-config($theme);

}


@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
