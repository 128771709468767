@use '@angular/material' as mat;
// Import all the tools needed to customize the theme and extract parts of it

// Define a mixin that accepts a theme and outputs the color styles for the component.
@mixin setup-component-theme($theme) {
  // Extract whichever individual palettes you need from the theme.
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  // Use mat-color to extract individual colors from a palette as necessary.
  cm-setup {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: mat.get-color-from-palette($primary);

    .setup-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      width: 100%;
      height: 100%;

      .logo {
        width: 600px;
        margin-bottom: 30px;
      }

      .setup-title {
        color: white;
        font-size: 50px;
        margin-bottom: 30px;
        text-align: center;
      }

      .setup-subtitle {
        font-size: 40px;
        color: white;
        text-align: center;
      }

      .ok-button {
        margin-top: 30px;
        width: 100px;
        height: 100px;
      }
    }
  }
}
