// Import all the tools needed to customize the theme and extract parts of it
@import "@angular/material/theming";

// Define a mixin that accepts a theme and outputs the color styles for the component.
@mixin accept-call-dialog-component-theme($theme) {
  // Extract whichever individual palettes you need from the theme.
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);

  // Use mat-color to extract individual colors from a palette as necessary.
  cm-accept-call-dialog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .accept-call-dialog-container {
      .user-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;

        h3 {
          color: #777;
          font-size: 36px;
        }

        cm-avatar-thumb {
          margin-bottom: 20px;
        }
      }
    }
  }
}
