// Import all the tools needed to customize the theme and extract parts of it
@import "@angular/material/theming";

// Define a mixin that accepts a theme and outputs the color styles for the component.
@mixin church-choice-selector-choice-component-theme($theme) {
  // Extract whichever individual palettes you need from the theme.
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  //$background: map-get($theme, background);

  // Use mat-color to extract individual colors from a palette as necessary.
  div.cm-church-choice-selector-choice {
    position: relative;
    box-sizing: border-box;
    padding: 10px;
    margin: 0 10px;
    color: #fff;
    background-color: rgba($color: #009fe3, $alpha: 0.4);

    text-align: center;
    vertical-align: middle;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.active {
      border: 1px solid #fff;
      border-radius: 5px;
      background-color: rgba($color: #009fe3, $alpha: 0.8);
      z-index: 10;
    }

    &:focus {
      outline: none;
    }

    cm-avatar-thumb,
    mat-icon {
      margin-bottom: 10px;
    }

    .label {
      width: 100%;
      text-align: center;
      margin: 0;
      padding: 0;
      font-size: 22px;
      font-weight: bold;
    }

    .current-choice {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 16px;
      right: 20px;
      background-color: #fff;
      border-radius: 20px;
    }
  }
}
