/* You can add global styles to this file, and also import other style files */

@import url("https://fonts.googleapis.com/css?family=Arimo");
@import url("https://fonts.googleapis.com/css?family=Roboto");
@import url("https://fonts.googleapis.com/css?family=Baloo");

// Font sizes
$font-xsmall: 10px;
$font-small: 17px;
$font-medium: 32px;
$font-large: 42px;
$font-xlarge: 52px;

body {
  box-sizing: border-box;
  //background: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
  width: 1280px;
  height: 720px;
  color: white;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: "Baloo", cursive;
}

h1 {
  font-size: $font-xlarge;
  margin-bottom: 20px;
}

h2 {
  font-size: $font-large;
  margin-bottom: 20px;
}

h3 {
  font-size: $font-medium;
  margin-bottom: 10px;
}

h4 {
  font-size: $font-small;
}

h5 {
  font-size: $font-xsmall;
}

h6 {
  font-size: $font-xsmall;
  text-transform: capitalize;
}

p {
  font-size: $font-small;
  line-height: $font-small * 1.2;
}

// snackbar

.snackbar-success {
  background: #4caf50 !important;
}

.snackbar-warning {
  background: red !important;
}
